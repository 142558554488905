
import { defineComponent, onMounted, ref } from "vue";
import CryptoJS from "crypto-js";

export default defineComponent({
    setup() {
        const decryptedEmail = ref("");

        onMounted(() => {
            getEmail();
        });

        function getEmail() {
            // const ciphertext = CryptoJS.AES.encrypt(
            //     "mailto:sacha@sachagoldman.com",
            //     "poaidsfjiudsafhouWHQe7y91378r"
            // ).toString();

            // console.log(ciphertext);

            decryptedEmail.value = CryptoJS.AES.decrypt(
                "U2FsdGVkX18u/dFIZChmeNzhdMiQzbq9ZKA+YdAm+W2B93o0jaOCJsr/FPreXW/u",
                "poaidsfjiudsafhouWHQe7y91378r"
            ).toString(CryptoJS.enc.Utf8);
        }

        return { decryptedEmail };
    },
});
